import React from "react";
import logo from "./logo.png";
import downloadIcon from "./Download_on_the_app_store.svg";
import "./App.css";
import { Router, Link } from "@reach/router";
import Privacy from "./Privacy";
import Invitation from "./Invitation";

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Simple, collaborative todo list.</p>
        <a
          className="App-link"
          href="https://itunes.apple.com/app/id1481493715"
        >
          <img src={downloadIcon} alt="Download on the App Store" />
        </a>
      </header>

      <footer>
        <Link to="/privacy">privacy policy</Link>
      </footer>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Home path="/" />
      <Privacy path="/privacy" />
      <Invitation path="/invitation/:invitationId/:invitationSecret" />
    </Router>
  );
}

export default App;
