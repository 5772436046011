import React from "react";
import logo from "./logo.png";
import downloadIcon from "./Download_on_the_app_store.svg";
import * as clipboard from "clipboard-polyfill";
import QRCode from "react-qr-code";

export default function Invitation({ invitationId, invitationSecret }) {
  const url = `toddos:///invitation?i=${invitationId}&s=${invitationSecret}`;

  function handleClickStore(e) {
    e.preventDefault();
    try {
      clipboard.writeText(
        `toddos-open-invitation-on-launch:${invitationId}:${invitationSecret}`
      );
    } catch (e) {
      console.log("error writing link to clipboard", e);
    }
    window.location.href = "https://itunes.apple.com/app/id1481493715";
  }

  return (
    <div className="App">
      <header className="App-header">
        <img
          src={logo}
          className="App-logo"
          alt="logo"
          style={{ width: 100, height: 100, marginTop: 20 }}
        />
        <p style={{ maxWidth: "400px" }}>
          In order to view this invitation, please install Toddos from the App
          Store on your mobile device. Then, reload this page.
        </p>
        <a
          className="App-link"
          href="https://itunes.apple.com/app/id1481493715"
          onClick={handleClickStore}
        >
          <img src={downloadIcon} alt="Download on the App Store" />
        </a>
        <div style={{ height: 20 }}></div>
        <p style={{ maxWidth: "400px" }}>
          After Toddos is installed, click below on your mobile device or scan
          the QR code to view the invitation:
        </p>

        <div style={{ marginBottom: "10px" }}>
          <a className="App-link" href={url}>
            View Invitation in Toddos
          </a>
        </div>

        <QRCode value={url} />
      </header>
    </div>
  );
}
